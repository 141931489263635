/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {
    var map = null;
    var $grid = null;
    var controller = new ScrollMagic.Controller();
    var ease = Expo.easeInOut;
    function _width(selector) {
        var width = selector[0].getBoundingClientRect().width;
        width -=
            parseInt(selector.css("padding-left")) +
            parseInt(selector.css("padding-right"));
        width -= selector.outerWidth(false) - selector.innerWidth();
        return width;
    }
    function _height(selector) {
        if (selector.length > 0) {
            var height = selector[0].getBoundingClientRect().height;
            height -=
                parseInt(selector.css("padding-top")) +
                parseInt(selector.css("padding-bottom"));
            height -= selector.outerHeight(false) - selector.innerHeight();
            return height;
        }
        return 0;
    }
    $.fn.replace_html = function (html, speed) {
        if (!speed) {
            speed = 300;
        }
        return this.each(function () {
            var el = $(this);
            el.stop(true, true, false);
            el.css({ height: "auto", opacity: 0 });
            var cur = { height: el.outerHeight() + "px" };
            el.html(html);
            var finish = { height: el.outerHeight() + "px", opacity: 1 };
            el.css(cur).animate(finish, speed, "swing");
        });
    };

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        base_url: document.head.querySelector("[name=base_url]").content,
        common: {
            init: function () {
                $(document).foundation(); // Foundation JavaScript

                Sage.common.mobile_nav();
                Sage.common.wow();
                Sage.common.scroll_to();
                Sage.common.fixedNav();
                Sage.common.slideshows();
                Sage.common.parallaxBackground();
                Sage.common.detailGrid();
                Sage.common.navIndicator();
                Sage.common.imageCompare();

                Sage.common.responsiveImages();
                $(window).resize(Sage.common.responsiveImages);
            },
            zoom: 15,
            new_map: function ($el) {
                var $markers = $el.find(".marker");
                var args = {
                    zoom: Sage.common.zoom,
                    center: new google.maps.LatLng(0, 0),
                    mapTypeId: google.maps.MapTypeId.ROADMAP,
                    styles: [
                        {
                            featureType: "administrative",
                            elementType: "all",
                            stylers: [
                                { lightness: "0" },
                                { gamma: "1.00" },
                                { visibility: "simplified" },
                            ],
                        },
                        {
                            featureType: "administrative",
                            elementType: "labels.text.fill",
                            stylers: [{ color: "#959595" }],
                        },
                        {
                            featureType: "landscape",
                            elementType: "all",
                            stylers: [{ color: "#f2f2f2" }],
                        },
                        {
                            featureType: "poi",
                            elementType: "all",
                            stylers: [{ visibility: "off" }],
                        },
                        {
                            featureType: "road",
                            elementType: "all",
                            stylers: [
                                { saturation: -100 },
                                { lightness: 45 },
                                { visibility: "simplified" },
                            ],
                        },
                        {
                            featureType: "road.highway",
                            elementType: "all",
                            stylers: [{ visibility: "simplified" }],
                        },
                        {
                            featureType: "road.highway",
                            elementType: "geometry.fill",
                            stylers: [{ color: "#ffffff" }],
                        },
                        {
                            featureType: "road.arterial",
                            elementType: "labels.icon",
                            stylers: [{ visibility: "off" }],
                        },
                        {
                            featureType: "transit",
                            elementType: "all",
                            stylers: [{ visibility: "off" }],
                        },
                        {
                            featureType: "water",
                            elementType: "all",
                            stylers: [
                                { color: "#dde6e8" },
                                { visibility: "on" },
                            ],
                        },
                    ],
                };
                var map = new google.maps.Map($el[0], args);
                map.markers = [];
                $markers.each(function () {
                    Sage.common.add_marker($(this), map);
                });
                Sage.common.center_map(map);
                return map;
            },
            add_marker: function ($marker, map) {
                var icon = {
                    path: "M36.7 18.4C36.7 8.2 28.5 0 18.4 0A18.42 18.42 0 0 0 3.5 29.2l14.8 25.7 16.5-28.3h-.1c1.3-2.5 2-5.3 2-8.2zM18.4 23c-2.9 0-5.2-2.3-5.2-5.2s2.3-5.2 5.2-5.2 5.2 2.3 5.2 5.2-2.4 5.2-5.2 5.2z",
                    fillColor: "#8F9934",
                    fillOpacity: 1,
                    anchor: new google.maps.Point(18, 54),
                    strokeWeight: 0,
                    scale: 1,
                };
                var latlng = new google.maps.LatLng(
                    $marker.attr("data-lat"),
                    $marker.attr("data-lng")
                );
                var marker = new google.maps.Marker({
                    position: latlng,
                    icon: icon,
                    map: map,
                });
                map.markers.push(marker);
                if ($marker.html()) {
                    var infowindow = new google.maps.InfoWindow({
                        content: $marker.html(),
                    });
                    google.maps.event.addListener(marker, "click", function () {
                        infowindow.open(map, marker);
                    });
                }
            },
            center_map: function (map) {
                var bounds = new google.maps.LatLngBounds();
                $.each(map.markers, function (i, marker) {
                    var latlng = new google.maps.LatLng(
                        marker.position.lat(),
                        marker.position.lng()
                    );
                    bounds.extend(latlng);
                });
                if (map.markers.length === 1) {
                    map.setCenter(bounds.getCenter());
                    map.setZoom(Sage.common.zoom);
                } else {
                    map.fitBounds(bounds);
                }
            },
            mobile_nav: function () {
                $("button.hamburger").click(function () {
                    $(this).toggleClass("active");
                    $("body").toggleClass("menu_open");
                    return false;
                });
                $(".mobile_nav .menu-item-has-children span").click(
                    function () {
                        $(this)
                            .parents(".menu-item-has-children")
                            .toggleClass("is-open");
                    }
                );
                $(window).resize(function () {
                    $(".hamburger").removeClass("active");
                    $("body").removeClass("menu_open");
                });
            },
            wow: function () {
                // set the controller for animations
                var duration = 1;

                // single wow - fadeIn & fadeInUp
                $(".wow, #wpadminbar").each(function (i) {
                    var tl = new TimelineMax();
                    var delay = $(this).data("delay") || 0;
                    var t1_duration = $(this).data("duration") || duration;

                    var animation = { ease: ease };

                    if (
                        $(this).hasClass("fadeIn") ||
                        $(this).is("#wpadminbar")
                    ) {
                        animation.ease = ease;
                        animation.autoAlpha = 0;
                        tl.from($(this), t1_duration, animation, delay);
                    }

                    if ($(this).hasClass("fadeInUp")) {
                        animation.ease = ease;
                        animation.autoAlpha = 0;
                        animation.y = 30;
                        tl.from($(this), t1_duration, animation, delay);
                    }

                    new ScrollMagic.Scene({
                        triggerElement: this,
                        triggerHook: 0.9,
                        reverse: false,
                    })
                        .setTween(tl) // trigger a TweenMax.to tween
                        .addTo(controller);
                });

                // series wow - fadeIn & fadeInUp
                $(".wows").each(function (i) {
                    var tl = new TimelineMax();
                    var delay = $(this).data("delay") || 0;
                    var t1_duration = $(this).data("duration") || duration;

                    var animation = { ease: ease };

                    if ($(".fadeIn", this)) {
                        animation.ease = ease;
                        animation.autoAlpha = 0;
                        tl.staggerFrom(
                            $(".fadeIn", this),
                            t1_duration,
                            animation,
                            0.05,
                            delay
                        );
                    }

                    if ($(".fadeInUp", this)) {
                        animation.ease = ease;
                        animation.autoAlpha = 0;
                        animation.y = 30;
                        tl.staggerFrom(
                            $(".fadeInUp", this),
                            t1_duration,
                            animation,
                            0.05,
                            delay
                        );
                    }

                    new ScrollMagic.Scene({
                        triggerElement: this,
                        triggerHook: 0.9,
                        reverse: false,
                    })
                        .setTween(tl) // trigger a TweenMax.to tween
                        .addTo(controller);
                });
            },
            scroll_to: function () {
                $(document).on("click", 'a[href^="#"]', function (e) {
                    e.preventDefault();

                    var link = $(this).attr("href").substring(1);
                    if ($("[name=" + link + "]")) {
                        $("html, body").animate(
                            {
                                scrollTop: $("[name=" + link + "]").offset()
                                    .top,
                            },
                            500
                        );
                    }
                });
            },
            fixedNav: function () {
                $(window).on("scroll", function () {
                    scrollPosition = $(this).scrollTop();
                    if (scrollPosition >= 150) {
                        $("header.header").addClass("prepfixed");
                    } else {
                        $("header.header").removeClass("prepfixed");
                    }

                    if (scrollPosition >= 300) {
                        $("header.header").addClass("fixed");
                    } else {
                        $("header.header").removeClass("fixed");
                    }
                });
            },
            navIndicator: function () {
                var menu = $(
                    "header.header .header__content #menu-primary-navigation"
                );
                var activeItem = $(".sub-menu .current-menu-item", menu);
                var activeParent = activeItem.parents("li");
                var activeItemText = activeItem.text();
                var activeItemLink = $("a", activeItem).attr("href");
                $("span.indicator", menu).remove();

                if ($(".header-category").length) {
                    // do it to it
                    activeParent = $("#menu-item-4723");
                    activeItemText = $(".header-category").text();
                    activeItemLink = $(".header-category").data("link");
                    activeParent.addClass("current-menu-ancestor");
                }

                $(".curr_page", activeParent)
                    .first()
                    .html(
                        '<a href="' +
                            activeItemLink +
                            '">' +
                            activeItemText +
                            "</a>"
                    )
                    .fadeIn();
            },
            slideshows: function () {
                var sliderList = [];
                $(".slideshow").each(function (index, element) {
                    var $this = $(".swiper-container", this);
                    var swiper = new Swiper($this, {
                        speed: 600,
                        spaceBetween: 0,
                        autoHeight: true,
                        pagination: {
                            el: $this.find(".swiper-pagination")[0],
                        },
                        loop: true,
                        navigation: {
                            nextEl: $this.find(".swiper-button-next")[0],
                            prevEl: $this.find(".swiper-button-prev")[0],
                        },
                    });
                    sliderList.push(swiper);
                });
            },
            responsiveImages: function () {
                if (Foundation.MediaQuery.is("small only")) {
                    $("[data-img-small]").each(function () {
                        $(this).css(
                            "backgroundImage",
                            "url(" + $(this).data("img-small") + ")"
                        );
                    });
                }
                if (Foundation.MediaQuery.is("medium only")) {
                    $("[data-img-medium]").each(function () {
                        $(this).css(
                            "backgroundImage",
                            "url(" + $(this).data("img-medium") + ")"
                        );
                    });
                }
                if (Foundation.MediaQuery.atLeast("large")) {
                    $("[data-img-large]").each(function () {
                        $(this).css(
                            "backgroundImage",
                            "url(" + $(this).data("img-large") + ")"
                        );
                    });
                }
            },
            parallaxBackground: function () {
                var _h = $(window).height();
                var _w = $(window).width();
                var parallax = [];
                $(".bcg").each(function (i) {
                    $(this).addClass("bcg-" + i);
                    parallax.push(".bcg-" + i);
                });

                var controller = new ScrollMagic.Controller();
                $.each(parallax, function (k, bcg) {
                    var container = $(bcg).parent();

                    new ScrollMagic.Scene({
                        duration: "200%",
                        triggerHook: 1,
                        triggerElement: container,
                        offset:
                            container.position().top - container.height() - 250,
                    })
                        .setTween(TweenMax.to(bcg, 1, { top: -250 }))
                        .addTo(controller);
                });
            },
            detailGrid: function () {
                var detailList = $(".detail-list").isotope({
                    itemSelector: ".detail-list__content",
                    percentPosition: true,
                    transitionDuration: 0,
                    masonry: {
                        gutter: 0,
                    },
                });
                $(window).load(function () {
                    detailList.isotope("layout");
                });
            },
            imageCompare: function () {
                $(".before-after").each(function () {
                    var controller = new ScrollMagic.Controller();
                    var tl = new TimelineMax();

                    var Cont = { val: 30 },
                        NewVal = 50;

                    var ic = $(".before-after__images", this)
                        .imagesCompare()
                        .data("imagesCompare");
                    tl.to(
                        $(".before-after__images", this),
                        1,
                        { autoAlpha: 1 },
                        0
                    );
                    tl.to(
                        Cont,
                        2,
                        {
                            val: NewVal,
                            onUpdate: function () {
                                ic.setValue(Cont.val / 100, false);
                            },
                            ease: Elastic.easeOut.config(1, 0.5),
                        },
                        0
                    );

                    var scene = new ScrollMagic.Scene({
                        triggerElement: this,
                        triggerHook: 0.67,
                    })
                        .setTween(tl) // trigger a TweenMax.to tween
                        .addTo(controller);
                });
            },
        },
        grid: {
            text_height: function () {
                var max = 0;
                if ($(".grid-cell__text")) {
                    $(".grid-cell__text").height("auto");
                    $(".grid-cell__text").each(function () {
                        var _h = $(this).height();
                        max = _h > max ? _h : max;
                    });
                    $(".grid-cell__text").height(max);
                }
            },
            large_image_height: function () {
                var max = 0;
                var gutter = _height($(".grid-cell__text"));
                $(".grid-cell--large .grid-cell__image").height("auto");
                $(".grid-cell--large").each(function () {
                    var _h;
                    if ($(window).width() > 1023) {
                        // include gutters of row span on large
                        _h = _width($(".grid-sizer")) * 2 + gutter - 20;
                        max = _h > max ? _h : max;
                    } else {
                        _h = _width($(".grid-sizer")) - 20;
                        max = _h > max ? _h : max;
                    }
                });
                $(".grid-cell--large .grid-cell__image").height(max);
            },
            quote_height: function () {
                if ($(window).width() > 1023) {
                    // include gutters of row span on large
                    var _h = _width($(".grid-sizer"));
                    var gutter = _height($(".grid-cell__text"));

                    $(
                        ".grid-cell--quote blockquote, .grid-cell--intro .tax-intro"
                    ).height(_h + gutter - 20);
                } else {
                    $(
                        ".grid-cell--quote blockquote, .grid-cell--intro .tax-intro"
                    ).height("auto");
                }
            },
            fix_heights: function () {
                Sage.grid.text_height();
                Sage.grid.large_image_height();
                Sage.grid.quote_height();
            },
        },
        home: {
            init: function () {
                var homeSwiper = new Swiper(
                    ".project-slider .swiper-container",
                    {
                        speed: 600,
                        spaceBetween: 0,
                        loop: true,
                        autoHeight: true,
                        pagination: {
                            el: ".project-slider .swiper-pagination",
                            clickable: true,
                        },
                        navigation: {
                            nextEl: ".project-slider .swiper-button-next",
                            prevEl: ".project-slider .swiper-button-prev",
                        },
                        on: {
                            init: function () {
                                if (
                                    $(
                                        ".project-slider .swiper-slide-active .project-slide"
                                    ).hasClass("is-anniversary")
                                ) {
                                    $(".project-slider__title").animate(
                                        { opacity: 0 },
                                        300
                                    );
                                } else {
                                    $(".project-slider__title").animate(
                                        { opacity: 1 },
                                        300
                                    );
                                }
                            },
                        },
                    }
                );
                homeSwiper.on("slideChange", function () {
                    var currentSlide = $(".swiper-slide").eq(this.activeIndex);
                    if (
                        $(".project-slide", currentSlide).hasClass(
                            "is-anniversary"
                        )
                    ) {
                        $(".project-slider__title").animate(
                            { opacity: 0 },
                            300
                        );
                    } else {
                        $(".project-slider__title").animate(
                            { opacity: 1 },
                            300
                        );
                    }
                });
            },
        },
        what_we_do: {
            vennController: false,
            vennDiagramDestroy: function () {
                if (Sage.what_we_do.vennController) {
                    Sage.what_we_do.vennController.destroy(true);
                    Sage.what_we_do.vennController = false;
                }
            },
            vennDiagram: function () {
                if ($(".venn")) {
                    if ($(window).width() < 640) {
                        Sage.what_we_do.vennDiagramDestroy();
                    } else {
                        if (!Sage.what_we_do.vennController) {
                            Sage.what_we_do.vennController =
                                new ScrollMagic.Controller();

                            // prep the digram movement
                            var w = 50,
                                w2 = 75,
                                p1 = { x: 100, y: 50 },
                                p2 = { x: 100, y: 100 },
                                p3 = { x: 150, y: 50 },
                                t = { w: 75, h: 15 },
                                t1 = { x: 25, y: 82 + t.h },
                                t2 = { x: 25, y: 142 + t.h },
                                t3 = { x: 225, y: 117 + t.h },
                                moveToPosition1_tl = new TimelineMax(),
                                moveToPosition2_tl = new TimelineMax(),
                                moveToPosition3_tl = new TimelineMax(),
                                delay = 0.25,
                                duration = 1,
                                ease = Power3.easeInOut,
                                ease2 = Power4.easeInOut,
                                wh = $(window).height(),
                                hh = $("header.header").height(),
                                vh = wh - hh,
                                vd_offset =
                                    vh - $(".venn").height() > 0
                                        ? -(vh - $(".venn").height()) * 0.5
                                        : 0,
                                vd_triggerHook = hh / wh,
                                vp_triggerHook = (vh * 0.5) / wh;
                            // pin the venn diagram
                            new ScrollMagic.Scene({
                                triggerElement: ".diagram-alley",
                                triggerHook: vd_triggerHook,
                                offset: vd_offset,
                                duration:
                                    $(".scenes").height() - $(".venn").height(),
                            })
                                .setPin(".venn")
                                .addTo(Sage.what_we_do.vennController);

                            // difenine the diagram steps
                            moveToPosition1_tl
                                .to(
                                    ".c1",
                                    duration,
                                    {
                                        ease: ease,
                                        attr: {
                                            cx: p1.x + w,
                                            cy: p1.y + w,
                                            r: w,
                                        },
                                    },
                                    delay
                                )
                                .to(
                                    ".c2",
                                    duration,
                                    {
                                        ease: ease,
                                        attr: {
                                            cx: p2.x + w,
                                            cy: p2.y + w,
                                            r: w,
                                        },
                                    },
                                    delay
                                )
                                .to(
                                    ".c3",
                                    duration,
                                    {
                                        ease: ease,
                                        attr: {
                                            cx: p3.x + w2,
                                            cy: p3.y + w2,
                                            r: w2,
                                        },
                                    },
                                    delay
                                )
                                .to(
                                    ".t1 image",
                                    duration,
                                    {
                                        ease: ease2,
                                        attr: { x: t1.x, y: t1.y },
                                        autoAlpha: 1,
                                    },
                                    delay
                                )
                                .to(
                                    ".t2 image",
                                    duration,
                                    {
                                        ease: ease2,
                                        attr: { x: t2.x, y: t2.y },
                                        autoAlpha: 1,
                                    },
                                    delay
                                )
                                .to(
                                    ".t3 image",
                                    duration,
                                    {
                                        ease: ease2,
                                        attr: { x: t3.x, y: t3.y },
                                        autoAlpha: 0,
                                    },
                                    delay
                                );

                            moveToPosition2_tl
                                .to(
                                    ".c1",
                                    duration,
                                    {
                                        ease: ease,
                                        attr: {
                                            cx: p2.x + w,
                                            cy: p2.y + w,
                                            r: w,
                                        },
                                    },
                                    delay
                                )
                                .to(
                                    ".c2",
                                    duration,
                                    {
                                        ease: ease,
                                        attr: {
                                            cx: p3.x + w2,
                                            cy: p3.y + w2,
                                            r: w2,
                                        },
                                    },
                                    delay
                                )
                                .to(
                                    ".c3",
                                    duration,
                                    {
                                        ease: ease,
                                        attr: {
                                            cx: p1.x + w,
                                            cy: p1.y + w,
                                            r: w,
                                        },
                                    },
                                    delay
                                )
                                .to(
                                    ".t1 image",
                                    duration,
                                    {
                                        ease: ease2,
                                        attr: { x: t2.x, y: t2.y },
                                        autoAlpha: 1,
                                    },
                                    delay
                                )
                                .to(
                                    ".t2 image",
                                    duration,
                                    {
                                        ease: ease2,
                                        attr: { x: t3.x, y: t3.y },
                                        autoAlpha: 0,
                                    },
                                    delay
                                )
                                .to(
                                    ".t3 image",
                                    duration,
                                    {
                                        ease: ease2,
                                        attr: { x: t1.x - 10, y: t1.y },
                                        autoAlpha: 1,
                                    },
                                    delay
                                );

                            moveToPosition3_tl
                                .to(
                                    ".c1",
                                    duration,
                                    {
                                        ease: ease,
                                        attr: {
                                            cx: p3.x + w2,
                                            cy: p3.y + w2,
                                            r: w2,
                                        },
                                    },
                                    delay
                                )
                                .to(
                                    ".c2",
                                    duration,
                                    {
                                        ease: ease,
                                        attr: {
                                            cx: p1.x + w,
                                            cy: p1.y + w,
                                            r: w,
                                        },
                                    },
                                    delay
                                )
                                .to(
                                    ".c3",
                                    duration,
                                    {
                                        ease: ease,
                                        attr: {
                                            cx: p2.x + w,
                                            cy: p2.y + w,
                                            r: w,
                                        },
                                    },
                                    delay
                                )
                                .to(
                                    ".t1 image",
                                    duration,
                                    {
                                        ease: ease2,
                                        attr: { x: t3.x, y: t3.y },
                                        autoAlpha: 0,
                                    },
                                    delay
                                )
                                .to(
                                    ".t2 image",
                                    duration,
                                    {
                                        ease: ease2,
                                        attr: { x: t1.x + 10, y: t1.y },
                                        autoAlpha: 1,
                                    },
                                    delay
                                )
                                .to(
                                    ".t3 image",
                                    duration,
                                    {
                                        ease: ease2,
                                        attr: { x: t2.x, y: t2.y },
                                        autoAlpha: 1,
                                    },
                                    delay
                                );

                            // move to position 2
                            new ScrollMagic.Scene({
                                triggerElement: ".diagram-alley",
                                triggerHook: vp_triggerHook,
                                offset: $(".scene--intro").outerHeight(true),
                            })
                                .setTween(moveToPosition1_tl)
                                // .addIndicators('placebranding')
                                .addTo(Sage.what_we_do.vennController);

                            new ScrollMagic.Scene({
                                triggerElement: ".diagram-alley",
                                triggerHook: vp_triggerHook,
                                offset:
                                    $(".scene--intro").outerHeight(true) +
                                    $(".scene--placebranding").outerHeight(
                                        true
                                    ),
                            })
                                .setTween(moveToPosition2_tl)
                                // .addIndicators('wayfinding')
                                .addTo(Sage.what_we_do.vennController);

                            // move to position 3
                            new ScrollMagic.Scene({
                                triggerElement: ".diagram-alley",
                                triggerHook: vp_triggerHook,
                                offset:
                                    $(".scene--intro").outerHeight(true) +
                                    $(".scene--placebranding").outerHeight(
                                        true
                                    ) +
                                    $(".scene--wayfinding").outerHeight(true),
                            })
                                .setTween(moveToPosition3_tl)
                                // .addIndicators('storytelling')
                                .addTo(Sage.what_we_do.vennController);
                        }
                    }
                }
            },
            init: function () {
                $(window).on("resize load", function () {
                    Sage.what_we_do.vennDiagram();
                });
                Sage.what_we_do.vennDiagram();

                $(".js-storytelling").click(function () {
                    if ($("[name=storytelling]")) {
                        var _h = $(window).height();
                        var offset = $("header.header").height();
                        var section_h = $(".scene--placebranding").height();
                        if (section_h + offset < _h) {
                            offset -= (section_h - offset - _h) * 0.25;
                        }
                        $("html, body").animate(
                            {
                                scrollTop:
                                    $("[name=storytelling]").offset().top -
                                    offset,
                            },
                            500
                        );
                    }
                });
                $(".js-wayfinding").click(function () {
                    if ($("[name=wayfinding]")) {
                        var _h = $(window).height();
                        var offset = $("header.header").height();
                        var section_h = $(".scene--placebranding").height();
                        if (section_h + offset < _h) {
                            offset -= (section_h - offset - _h) * 0.25;
                        }

                        $("html, body").animate(
                            {
                                scrollTop:
                                    $("[name=wayfinding]").offset().top -
                                    offset,
                            },
                            500
                        );
                    }
                });
                $(".js-placebranding").click(function () {
                    if ($("[name=placebranding]")) {
                        var _h = $(window).height();
                        var offset = $("header.header").height();
                        var section_h = $(".scene--placebranding").height();
                        if (section_h + offset < _h) {
                            offset -= (section_h - offset - _h) * 0.25;
                        }

                        $("html, body").animate(
                            {
                                scrollTop:
                                    $("[name=placebranding]").offset().top -
                                    offset,
                            },
                            500
                        );
                    }
                });
            },
        },
        how_we_do_it: {
            updateStepHeight: function () {
                setTimeout(function () {
                    $(".step-boxes").height("auto"); // reset
                    $(".step-boxes").height($(".step-boxes").height()); // set
                }, 400); // the css transition is 300ms. Waiit a littel extras and set the height
            },
            goToStep: function ($step, mySwiper, index) {
                $step.addClass("active").siblings().removeClass("active");
                mySwiper.slideTo(index, 300);
                Sage.how_we_do_it.updateStepHeight();
            },
            init: function () {
                var mySwiper = new Swiper(".step-infobox .swiper-container", {
                    speed: 400,
                    effect: "fade",
                    fadeEffect: {
                        crossFade: true,
                    },
                });
                $(".step-box").on("click", function () {
                    Sage.how_we_do_it.goToStep(
                        $(this),
                        mySwiper,
                        $(this).index()
                    );
                    return false;
                });
                $(".step-nav__prev").on("click", function () {
                    var curr = $(".step-box.active").index();
                    var prev = curr > 0 ? curr - 1 : $(".step-box").length - 1;
                    Sage.how_we_do_it.goToStep(
                        $(".step-box").eq(prev),
                        mySwiper,
                        prev
                    );
                    return false;
                });
                $(".step-nav__next").on("click", function () {
                    var curr = $(".step-box.active").index();
                    var next = curr < $(".step-box").length - 1 ? curr + 1 : 0;
                    Sage.how_we_do_it.goToStep(
                        $(".step-box").eq(next),
                        mySwiper,
                        next
                    );
                    return false;
                });
                $(window).resize(Sage.how_we_do_it.updateStepHeight);

                // move to slide
                new ScrollMagic.Scene({
                    triggerElement: ".step-boxes",
                    triggerHook: 0.75,
                })
                    .on("enter", function () {
                        if ($(".step-box.active").length < 1) {
                            Sage.how_we_do_it.goToStep(
                                $(".step-box").eq(0),
                                mySwiper,
                                0
                            );
                        }
                    })
                    .addTo(controller);
            },
        },
        connect: {
            init: function () {
                $(".acf-map").each(function () {
                    map = Sage.common.new_map($(this));
                });
            },
        },
        single_industries: {
            init: function () {
                $grid = $(".grid").isotope({
                    itemSelector: ".grid-cell",
                    percentPosition: true,
                    horizontalOrder: true,
                    masonry: {
                        columnWidth: ".grid-sizer",
                    },
                });
                $grid.isotope("layout");

                $(window).load(function () {
                    $grid.isotope("layout");
                });
                $(window).resize(function () {
                    $grid.isotope("layout");
                });
            },
        },
        people: {
            filterPeople: function () {
                if (Foundation.MediaQuery.atLeast("large")) {
                    $grid.isotope({ filter: "*" });
                } else {
                    $grid.isotope({ filter: ".grid-cell--person" });
                }
            },
            init: function () {
                $grid = $(".grid").isotope({
                    itemSelector: ".grid-cell",
                    percentPosition: true,
                    horizontalOrder: true,
                    transitionDuration: 0,
                    masonry: {
                        columnWidth: ".grid-sizer",
                    },
                });

                Sage.people.filterPeople();
                Sage.grid.fix_heights();
                $grid.isotope("layout");

                $(window).load(function () {
                    Sage.people.filterPeople();
                    Sage.grid.fix_heights();
                    $grid.isotope("layout");
                });
                $(window).resize(function () {
                    Sage.people.filterPeople();
                    Sage.grid.fix_heights();
                    $grid.isotope("layout");
                });
            },
        },
        news: {
            ajax: null,
            filters: {
                categories: [],
            },
            buildPost: function (post) {
                var tpl = "";
                var cats = "";
                if (post.categories && post.categories.length > 0) {
                    $.each(post.categories, function (i, cat) {
                        cats += " cat_" + cat.slug;
                    });
                }
                var dt = new Date(post.date + " 00:00:00").getTime() / 1000;
                tpl +=
                    '<div class="news-post ' +
                    cats +
                    '" data-dt="' +
                    dt +
                    '"  data-post-id="' +
                    post.id +
                    '">';
                if (post.featured_image) {
                    tpl +=
                        '<div class="news-post__featured-image" style="background-image: url(' +
                        post.featured_image +
                        ')">';
                    tpl +=
                        '<img src="' +
                        post.featured_image +
                        '" alt="' +
                        post.title +
                        '">';
                    tpl += "</div>";
                }
                tpl += '<div class="news-post__date">' + post.date + "</div>";
                tpl += '<h3 class="news-post__title">' + post.title + "</h3>";
                tpl +=
                    '<div class="news-post__excerpt">' +
                    post.excerpt +
                    "</div>";
                if (post.categories && post.categories.length > 0) {
                    tpl += '<div class="news-post__categories">';
                    $.each(post.categories, function (i, cat) {
                        tpl +=
                            '<a class="button category" data-cat="' +
                            cat.slug +
                            '">' +
                            cat.name +
                            "</a>";
                    });
                    tpl += "</div>";
                }
                tpl += '<a class="coverlink" href="' + post.url + '"></a>';
                tpl += "</div>";

                return tpl;
            },
            doSearch: function () {
                $(".post-list").addClass("loading");
                var s = $("#search").val();
                var posts_loaded = [];
                $(".news-post").each(function () {
                    posts_loaded.push($(this).data("post-id"));
                });

                // abort requests if too frequent
                if (
                    Sage.news.ajax &&
                    Sage.news.ajax.readyState &&
                    Sage.news.ajax.readyState !== 4
                ) {
                    Sage.news.ajax.abort();
                }

                Sage.news.ajax = $.ajax({
                    type: "POST",
                    url: Sage.base_url + "/wp-admin/admin-ajax.php",
                    data: {
                        action: "search_posts",
                        s: s,
                        posts_loaded: posts_loaded,
                        filters: Sage.news.filters,
                    },
                    success: function (data) {
                        var posts = JSON.parse(data);
                        $.each(posts, function (i, data) {
                            var post = Sage.news.buildPost(data);
                            var $content = $(post);
                            $grid
                                .append($content)
                                .isotope("appended", $content);
                            $(".button.category", $content).on(
                                "click",
                                function () {
                                    Sage.news.doURLupdate();
                                    Sage.news.doFilter($(this)); // send in the requested filter
                                    Sage.news.doSearch(); // send in the requested filter
                                }
                            );
                        });

                        $grid.imagesLoaded().progress(function () {
                            $grid.isotope("layout");
                        });

                        if (posts.length > 24) {
                            $(".post-list__load-more").show();
                        } else {
                            $(".post-list__load-more").hide();
                        }
                    },
                    complete: function () {
                        $(".post-list").removeClass("loading");
                        Sage.news.doURLupdate();
                        Sage.news.updateFilterList();
                        $grid.isotope("updateSortData").isotope();
                    },
                });
            },
            doFilter: function (btn) {
                if (btn) {
                    var cat = btn.data("cat");
                    if (Sage.news.filters.categories.indexOf(cat) === -1) {
                        Sage.news.filters.categories.push(cat);
                    }
                }

                if (Sage.news.filters.categories.length > 0) {
                    var filter = "";
                    $.each(Sage.news.filters.categories, function (i, cat) {
                        if (i > 0) {
                            filter += ", ";
                        }
                        filter += ".cat_" + cat;
                    });
                    $grid.isotope({
                        filter: filter,
                    });
                } else {
                    $grid.isotope({
                        filter: function () {
                            return true;
                        },
                    });
                }

                if (!$grid.data("isotope").filteredItems.length) {
                    $(".post-list__no-results").show();
                    $(".post-list__load-more").hide();
                } else {
                    $(".post-list__no-results").hide();
                }
            },
            doURLupdate: function () {
                var filters = Sage.news.filters;
                var vars = [];
                for (var key in filters) {
                    if (filters.hasOwnProperty(key)) {
                        $.each(filters[key], function (i, val) {
                            vars.push(key + "[]=" + val);
                        });
                    }
                }
                vars = vars.join("&");
                if (vars.length > 0) {
                    history.replaceState(
                        null,
                        null,
                        Sage.base_url + "/news/?" + vars
                    );
                } else {
                    history.replaceState(null, null, Sage.base_url + "/news/");
                }
            },
            decodeFilters: function () {
                var query = window.location.search.substring(1);
                var vars = query.split("&");
                for (var i = 0; i < vars.length; i++) {
                    var pair = vars[i].split("=");
                    switch (pair[0]) {
                        case "categories[]":
                        case "categories%5B%5D":
                            Sage.news.filters.categories.push(pair[1]);
                            break;
                    }
                }
            },
            updateFilterList: function () {
                $(".post-filters li").removeClass("active");
                if (Sage.news.filters.categories.length > 0) {
                    $.each(Sage.news.filters.categories, function (i, slug) {
                        $(".post-filters li a[data-cat=" + slug + "]")
                            .parent()
                            .addClass("active");
                    });
                }
            },
            init: function () {
                Sage.news.decodeFilters();
                $grid = $(".post-list__posts").isotope({
                    itemSelector: ".news-post",
                    getSortData: {
                        date: "[data-dt] parseInt",
                    },
                    sortBy: "date",
                    sortAscending: false,
                });
                Sage.news.doSearch();

                // // search stuff
                $(".search_projects input[type=submit]").click(function () {
                    $(".post-list__load-more").show();
                    $grid.isotope("remove", $(".news-post"));
                    Sage.news.doSearch();
                });
                $("#search").on("keyup", function (e) {
                    if (e.keyCode === 13) {
                        $(".post-list__load-more").show();
                        $grid.isotope("remove", $(".news-post"));
                        Sage.news.doSearch();
                    }
                });

                $(".post-list__load-more a").on("click", function () {
                    Sage.news.doSearch();
                    this.blur();
                    return false;
                });
                $(".post-filters li a").on("click", function () {
                    var slug = $(this).data("cat");
                    var index = Sage.news.filters.categories.indexOf(slug);
                    if (index > -1) {
                        Sage.news.filters.categories.splice(index, 1);
                    }
                    Sage.news.updateFilterList();
                    Sage.news.doURLupdate();
                    Sage.news.doFilter();
                    Sage.news.doSearch();
                    this.blur();
                    return false;
                });
            },
            concatValues: function (obj) {
                var value = "";
                for (var prop in obj) {
                    value += obj[prop];
                }
                return value;
            },
        },
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = funcname === undefined ? "init" : funcname;
            fire = func !== "";
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === "function";

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire("common");

            // Fire page-specific init JS, and then finalize JS
            $.each(
                document.body.className.replace(/-/g, "_").split(/\s+/),
                function (i, classnm) {
                    UTIL.fire(classnm);
                    UTIL.fire(classnm, "finalize");
                }
            );

            // Fire common finalize JS
            UTIL.fire("common", "finalize");
        },
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);
})(jQuery); // Fully reference jQuery after this point.
